import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';

import { Subject } from 'rxjs';
import { GetProjectsRequest } from '../../../dto/api.dtos';
import { ApiService } from '../../services/api.service';
import { SettingsService } from '../../services/settings.service';
import { SidenavService } from '../../services/sidenav.service';

const sidenavItemsTemp = [
  {
    path: '/project/1',
    icon: 'emoji_transportation',
    name: 'Проект УДИБ',
    perm: 'catchDog.read',
    nameRu: 'Отлов собак',
  },
  {
    path: '/project/2',
    icon: 'work',
    name: 'Проект САТП',
    perm: 'catchDog.read',
    nameRu: 'Отлов собак',
  },
  {
    path: '/project/3',
    icon: 'warning',
    name: 'КрасДорЗнак',
    perm: 'catchDog.read',
    nameRu: 'Отлов собак',
  },
];
const sidenavItemsSetting = [
  { path: '/settings', icon: 'settings', name: 'Настройки' },
];
const sidenavItemsUtils = [
  { path: '/search', icon: 'search', name: 'Главная' },
];
const maxWidth = '250px';
const minWidth = '62px';
export const sidenavAnimation = trigger('sidenavAnimation', [
  state(
    'expanded',
    style({
      width: maxWidth,
    })
  ),
  state(
    'collapsed',
    style({
      width: minWidth,
    })
  ),
  transition('expanded <=> collapsed', [animate('0.17s')]),
]);

export const sidenavContentAnimation = trigger('sidenavContentAnimation', [
  state(
    'expanded',
    style({
      'margin-left': maxWidth,
    })
  ),
  state(
    'collapsed',
    style({
      'margin-left': minWidth,
    })
  ),
  transition('expanded <=> collapsed', [animate('0.1s')]),
]);

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [sidenavAnimation, sidenavContentAnimation],
})
export class SidenavComponent implements OnInit {
  _destroy = new Subject<any>();
  sidenavItems = [];
  sidenavItemsSetting = sidenavItemsSetting;
  sidenavItemsUtils = sidenavItemsUtils;
  constructor(
    public sidenavService: SidenavService,
    public translate: TranslateService,
    private _router: Router,
    private _api: ApiService,
    private _changeDetector: ChangeDetectorRef,
    private _settingsService: SettingsService
  ) {}

  ngOnInit() {
    this._loadData();
    this._settingsService.updateSidenav.subscribe(() => {
      this._loadData();
    });
    // this._router.navigate(['/home']);
    // this.sidenavItems = this.sidenavItemsTemp;
  }
  _loadData() {
    this.sidenavItems = [];
    const request = new GetProjectsRequest();
    this._api.get(request).subscribe((result) => {
      result.items.forEach((item) => {
        this.sidenavItems.push({
          path: '/project/' + item.id,
          icon: 'work',
          name: item.name,
          perm: '',
          nameRu: '',
        });
      });
      this._changeDetector.markForCheck();
    });
  }
  get expanded() {
    return this.sidenavService.expanded; // || this.sidenavService.showing;
  }
  contentAnimationDone() {
    this.sidenavService.changedAnimationDone.next();
  }

  click(item) {
    if (item.group) {
      item.groupExpanded = !item.groupExpanded;
    }
  }

  get over() {
    return true;
  }
  isActive(item) {
    return this._router.isActive(item.path, false);
  }

  // about() {
  //   this._dialogService.about();
  // }
}
