<mat-toolbar>
  <div class="navbar">
    <a mat-icon-button class="menu-button" (click)="toggleSidenav()">
      <mat-icon>menu</mat-icon>
    </a>

    <span class="title">{{ "APP.TITLE" | translate }}</span>
    <div class="flex-spacer"></div>

    <!-- <div>
        {{displayName}}
      </div> -->
    <button
      mat-button
      [matMenuTriggerFor]="userMenu"
      style="width: auto; padding: 0 8px 0 16px; height: 50px"
    >
      <span style="margin-right: 5px">{{ displayName }}</span>
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #userMenu="matMenu" overlapTrigger="false" class="user-menu">
      <button mat-menu-item (click)="selectLang()">
        <mat-icon>language</mat-icon>
        <span
          >{{ "COMMON.LANGUAGE" | translate }}: {{ getCurrentLangName() }}</span
        >
      </button>
      <mat-divider></mat-divider>
      <!-- <button mat-menu-item (click)="changeTheme()">
          <div *ngIf="isDarkButton === false">
            <mat-icon>brightness_3</mat-icon>
            <span>Темная тема </span>
          </div>
          <div *ngIf="isDarkButton === true">
            <mat-icon>wb_sunny</mat-icon>
            <span>Светлая тема </span>
          </div>
        </button> -->

      <!-- 
        <button mat-menu-item (click)="settings()">
          <div *ngIf="isSettings === false">
          <mat-icon>brightness_3</mat-icon>
          <span>{{ "COMMON.NOT_SETTINGS" | translate }} </span>
        </div>
        <div *ngIf="isSettings === true">
          <mat-icon>wb_sunny</mat-icon>
          <span>{{ "COMMON.SETTINGS" | translate }} </span>
        </div>
        </button> -->
      <!-- <div *ngIf="isAdmin">
          <mat-divider></mat-divider>
          <mat-slide-toggle
            [checked]="expanded"
            (click)="toggleAdminSetting()"
            style="height: 48px; margin: 0 12px; font-family: Roboto, sans-serif; font-size: 14px; font-weight: 400;"
          >
            <span style="color: white;">{{ "COMMON.SETTINGS" | translate }}</span>
          </mat-slide-toggle>
        </div> -->

      <mat-divider></mat-divider>

      <button mat-menu-item (click)="logOut()">
        <mat-icon>exit</mat-icon>
        <span>{{ "COMMON.EXIT" | translate }}</span>
      </button>
    </mat-menu>
  </div>
</mat-toolbar>
