import { Component, OnInit, AfterViewInit } from '@angular/core';
import { SidenavService } from '../../services/sidenav.service';

import { TranslateService } from '@ngx-translate/core';
import {
  AuthService,
  DialogService,
  FlashService,
  TokenService,
} from 'msu-its-web-common-lib';
import { SelectLangComponent } from './select-lang.component';


export const langs = {
  ru: 'Русский',
  en: 'English',
};

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, AfterViewInit {
  isDarkButton: boolean;
  isAdmin: boolean;

  constructor(
    private _sidenavService: SidenavService,
    private _dialogService: DialogService,
    private _flashService: FlashService,
    private _authService: AuthService,
    public translate: TranslateService,
    private _tokenService: TokenService
  ) {}

  ngOnInit() {
    if (this._sidenavService.expanded) {
      this._sidenavService.toggle();
    }
    // if (this._adminSettingService.expanded) {
    //   this._adminSettingService.toggle();
    // }

    // this.isDarkButton = this._settingsService.isDarkTheme;
  }

  ngAfterViewInit() {}

  toggleSidenav() {
    this._sidenavService.toggle();
  }

  // get expanded() {
  //   return this._adminSettingService.expanded; // || this.sidenavService.showing;
  // }

  // toggleAdminSetting() {
  //   this._adminSettingService.toggle();
  // }

  getCurrentLangName() {
    const langName = langs[this.translate.currentLang];
    return langName ? langName.toLowerCase() : '';
  }

  selectLang() {
    const dialog = this._dialogService.dialog.open(SelectLangComponent, {
      disableClose: false,
    });
    dialog.afterClosed().subscribe((lang) => {
      if (lang) {
        this.translate.get('COMMON.LANGUAGE').subscribe((val) => {
          this._flashService.success(val + ': ' + langs[lang].toLowerCase());
        });
      }
    });
  }
  changeTheme() {
    this.isDarkButton = !this.isDarkButton;
    // this._settingsService.isDarkTheme = this.isDarkButton;
    // console.log('this._settingsService.isDarkTheme' + this._settingsService.isDarkTheme);
  }

  logOut() {
    this._authService.logout();
  }
  get displayName() {
    return this._tokenService.getValue('name');
  }
}
