import { Injectable, Inject, Injector } from '@angular/core';
import { BaseApiService } from 'msu-its-web-common-lib';
import { Observable } from 'rxjs';
import { FileItem, ItsFile } from 'src/app/dto/api.dtos';

@Injectable()
export class ApiService extends BaseApiService {
  constructor(injector: Injector, @Inject('APP_ENVIRONMENT') appEnv) {
    super(injector, appEnv.tt.api);
  }

  upload(
    file,
    entityId: string,
    projectId: string,
    description?: string
  ): Observable<ItsFile> {
    const url = `${this.apiUrl}/itsFiles/upload`;
    const formData = new FormData();
    // formData.append('entityId', entityId);
    formData.append('name', file.name);
    formData.append('projectId', projectId);
    // formData.append('name', encodeURI(file.name));
    formData.append('description', description || ' ');
    formData.append('file', file);
    return this._handleResult(
      fetch(url, {
        method: 'POST',
        headers: {
          Accept: file.type,
          Authorization: `Bearer ${this._tokenService.bearerToken}`,
        },
        mode: 'cors',
        body: formData,
      })
        .then((response) => Promise.all([response.ok, response.json()]))
        .then((result) => {
          if (result[0]) {
            return result[1];
          } else {
            throw result[1];
          }
        })
    );
  }
}
