/* Options:
Date: 2021-10-12 03:38:12
Version: 5.110
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://api.dev.support.msu24.ru

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IPost
{
}

export interface IProjectRequest
{
    // @DataMember(IsRequired=true)
    projectId: string;
}

export interface IBaseEntityItemRequest
{
    id: string;
}

export interface IDescription
{
    // @DataMember
    description: string;
}

// @DataContract
export class User implements ISecurityUser, IEntity
{
    // @DataMember
    public userName: string;

    // @DataMember
    public displayName: string;

    // @DataMember
    public email: string;

    // @DataMember
    public phone: string;

    // @DataMember
    public customer: string;

    // @DataMember
    public customerId: string;

    // @DataMember
    public title: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<User>) { (Object as any).assign(this, init); }
}

// @Flags()
// @DataContract
export enum Roles
{
    Observer = 'Observer',
    Performer = 'Performer',
    Manager = 'Manager',
    Administrator = 'Administrator',
}

// @DataContract
export class ItsTaskPreview implements INamedEntity, IEntity
{
    // @DataMember
    public number: number;

    // @DataMember
    public status: string;

    // @DataMember
    public priority: number;

    // @DataMember
    public assignedUserId?: string;

    // @DataMember
    public assignedUserName: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public projectKanbanColumnId: string;

    // @DataMember
    public position: number;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public author: string;

    // @DataMember
    public name: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<ItsTaskPreview>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ItsTaskAssignedGroupPreview
{
    // @DataMember
    public assignedId?: string;

    // @DataMember
    public assignedName: string;

    // @DataMember
    public kanbanColumnId: string;

    // @DataMember
    public tasks: ItsTaskPreview[];

    public constructor(init?: Partial<ItsTaskAssignedGroupPreview>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ItsTaskAuthoredGroupPreview
{
    // @DataMember
    public authorId: string;

    // @DataMember
    public authorName: string;

    // @DataMember
    public kanbanColumnId: string;

    // @DataMember
    public tasks: ItsTaskPreview[];

    public constructor(init?: Partial<ItsTaskAuthoredGroupPreview>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ItsTaskStatusGroupPreview
{
    // @DataMember
    public status: string;

    // @DataMember
    public kanbanColumnId: string;

    // @DataMember
    public tasks: ItsTaskPreview[];

    public constructor(init?: Partial<ItsTaskStatusGroupPreview>) { (Object as any).assign(this, init); }
}

// @DataContract
export enum ItsTaskPriority
{
    NoPriority = 'NoPriority',
    Low = 'Low',
    Medium = 'Medium',
    High = 'High',
    Immediate = 'Immediate',
    Critical = 'Critical',
}

// @DataContract
export class ItsTaskPriorityGroupPreview
{
    // @DataMember
    public priority: ItsTaskPriority;

    // @DataMember
    public kanbanColumnId: string;

    // @DataMember
    public tasks: ItsTaskPreview[];

    public constructor(init?: Partial<ItsTaskPriorityGroupPreview>) { (Object as any).assign(this, init); }
}

// @DataContract
export class Project implements INamedEntity, IEntity
{
    // @DataMember
    public projectImage: string;

    // @DataMember
    public hasLockedMembership: boolean;

    // @DataMember
    public hasKanban: boolean;

    // @DataMember
    public properties: string;

    // @DataMember
    public description: string;

    // @DataMember
    public name: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<Project>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ItsTaskDetails implements INamedEntity, IEntity
{
    // @DataMember
    public projects: Project[];

    // @DataMember
    public number: number;

    // @DataMember
    public status: string;

    // @DataMember
    public priority: ItsTaskPriority;

    // @DataMember
    public authorName: string;

    // @DataMember
    public created: string;

    // @DataMember
    public assignedUserId?: string;

    // @DataMember
    public assignedUserName: string;

    // @DataMember
    public closed?: string;

    // @DataMember
    public closerId?: string;

    // @DataMember
    public closerName: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public description: string;

    // @DataMember
    public properties: string;

    // @DataMember
    public begin?: string;

    // @DataMember
    public end?: string;

    // @DataMember
    public name: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<ItsTaskDetails>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ApplicationInfo implements INamed, IDescription
{
    // @DataMember
    public id: string;

    // @DataMember
    public name: string;

    // @DataMember
    public description: string;

    // @DataMember
    public instance: string;

    // @DataMember
    public version: string;

    // @DataMember
    public buildTime: string;

    public constructor(init?: Partial<ApplicationInfo>) { (Object as any).assign(this, init); }
}

export interface ICustomerItemRequest extends ICustomerEntity
{
}

export interface ICustomerEntity
{
    // @DataMember
    customerId: string;
}

export interface ICustomerIntegrationIdRequest
{
    customerIntegrationId: string;
}

export interface IEntity
{
}

export interface IEntityTyped
{
    // @DataMember
    entityType: string;
}

export interface IChangeKey extends IReadonlyChangeKey
{
    // @DataMember
    changeKey: string;
}

export interface IReadonlyChangeKey
{
    // @DataMember
    changeKey: string;
}

export interface IModified
{
    // @DataMember
    modifiedBy: string;

    // @DataMember
    modified: string;
}

// @DataContract
export enum CustomFieldType
{
    Bool = 'Bool',
    Double = 'Double',
    DateTime = 'DateTime',
    String = 'String',
    Spatial = 'Spatial',
}

export interface INamedEntity extends IEntity, INamed
{
}

export interface INamed
{
    // @DataMember
    name: string;
}

export interface ICustomerName
{
    // @DataMember
    customerName: string;
}

// @DataContract
export class Permission implements IPermission
{
    // @DataMember
    public id: string;

    // @DataMember
    public category: string;

    // @DataMember
    public entityType: string;

    // @DataMember
    public moduleId: string;

    // @DataMember
    public featureId: string;

    public constructor(init?: Partial<Permission>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ItsTaskCustomFieldStorage
{
    // @DataMember
    public id: string;

    // @DataMember
    public objectId: string;

    // @DataMember
    public objectFieldId: string;

    // @DataMember
    public value: string;

    public constructor(init?: Partial<ItsTaskCustomFieldStorage>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ItsTaskTransaction implements IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public author: string;

    // @DataMember
    public objectId: string;

    // @DataMember
    public title: string;

    // @DataMember
    public actionName: string;

    // @DataMember
    public icon: string;

    // @DataMember
    public color: string;

    // @DataMember
    public transactionType: string;

    // @DataMember
    public oldValue: string;

    // @DataMember
    public newValue: string;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<ItsTaskTransaction>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ItsTaskTransactionComment implements IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public transactionId: string;

    // @DataMember
    public viewPolicy: string;

    // @DataMember
    public editPolicy: string;

    // @DataMember
    public commentVersion: number;

    // @DataMember
    public content: string;

    // @DataMember
    public deleted: boolean;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<ItsTaskTransactionComment>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DetailedItsTaskTransaction implements IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public comment: ItsTaskTransactionComment;

    // @DataMember
    public author: string;

    // @DataMember
    public objectId: string;

    // @DataMember
    public title: string;

    // @DataMember
    public actionName: string;

    // @DataMember
    public icon: string;

    // @DataMember
    public color: string;

    // @DataMember
    public transactionType: string;

    // @DataMember
    public oldValue: string;

    // @DataMember
    public newValue: string;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<DetailedItsTaskTransaction>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EntityGroupInfo implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public itemsCount: number;

    // @DataMember
    public customerId: string;

    // @DataMember
    public customerName: string;

    // @DataMember
    public name: string;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<EntityGroupInfo>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export interface ISecurityUser extends IEntity
{
    displayName: string;
    userName: string;
}

// @DataContract
export class ItsTaskGroupPreviewBase
{
    // @DataMember
    public kanbanColumnId: string;

    // @DataMember
    public tasks: ItsTaskPreview[];

    public constructor(init?: Partial<ItsTaskGroupPreviewBase>) { (Object as any).assign(this, init); }
}

export interface IPermission extends IModuleId, IFeatureId
{
}

export interface IModuleId
{
    moduleId: string;
}

export interface IFeatureId
{
    featureId: string;
}

// @DataContract
export class TransactionViewModelBase implements IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public objectId: string;

    // @DataMember
    public title: string;

    // @DataMember
    public actionName: string;

    // @DataMember
    public icon: string;

    // @DataMember
    public color: string;

    // @DataMember
    public transactionType: string;

    // @DataMember
    public oldValue: string;

    // @DataMember
    public newValue: string;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<TransactionViewModelBase>) { (Object as any).assign(this, init); }
}

export class CustomerNamedBaseEntityViewModelBase implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public customerId: string;

    // @DataMember
    public customerName: string;

    // @DataMember
    public name: string;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<CustomerNamedBaseEntityViewModelBase>) { (Object as any).assign(this, init); }
}

export interface ICustomerEntityViewModel extends ICustomerName, ICustomerEntity
{
}

// @DataContract
export class CustomFieldValue implements IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public customFieldId: string;

    // @DataMember
    public value: string;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<CustomFieldValue>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CustomFieldValuesResponse
{
    // @DataMember
    public items: CustomFieldValue[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    public constructor(init?: Partial<CustomFieldValuesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CustomField implements INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public type: CustomFieldType;

    // @DataMember
    public caption: string;

    // @DataMember
    public required: boolean;

    // @DataMember
    public disabled: boolean;

    // @DataMember
    public edit: boolean;

    // @DataMember
    public isDictionary: boolean;

    // @DataMember
    public name: string;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<CustomField>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CustomFieldResponse
{
    // @DataMember
    public items: CustomField[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    public constructor(init?: Partial<CustomFieldResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ItsFile implements INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public storageType: string;

    // @DataMember
    public byteSize: number;

    // @DataMember
    public metadata: string;

    // @DataMember
    public path: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public name: string;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<ItsFile>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FileItem implements INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public entityId: string;

    // @DataMember
    public size: number;

    // @DataMember
    public contentType: string;

    // @DataMember
    public name: string;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<FileItem>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ItsFilesResponse
{
    // @DataMember
    public items: ItsFile[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    public constructor(init?: Partial<ItsFilesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProjectUserInfo implements ICustomerEntity, ICustomerName, IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public userId: string;

    // @DataMember
    public userName: string;

    // @DataMember
    public displayName: string;

    // @DataMember
    public projectCustomerId: string;

    // @DataMember
    public customerId: string;

    // @DataMember
    public customerName: string;

    // @DataMember
    public roles: Roles[];

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<ProjectUserInfo>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProjectUsersResponse
{
    // @DataMember
    public items: ProjectUserInfo[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    public constructor(init?: Partial<ProjectUsersResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AvailableUsersResponse
{
    // @DataMember
    public items: User[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    public constructor(init?: Partial<AvailableUsersResponse>) { (Object as any).assign(this, init); }
}

export class ProjectCustomer implements ICustomerEntity, ICustomerName, IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public customerId: string;

    // @DataMember
    public projectId: string;

    // @DataMember
    public roles: Roles[];

    // @DataMember
    public customerName: string;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<ProjectCustomer>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProjectCustomerResponse
{
    // @DataMember
    public items: ProjectCustomer[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    public constructor(init?: Partial<ProjectCustomerResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProjectKanbanTaskPosition implements IEntity
{
    // @DataMember
    public projectId: string;

    // @DataMember
    public kanbanColumnId: string;

    // @DataMember
    public taskId: string;

    // @DataMember
    public previous?: string;

    // @DataMember
    public next?: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<ProjectKanbanTaskPosition>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProjectKanbanTaskPositionsResponse
{
    // @DataMember
    public items: ProjectKanbanTaskPosition[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    public constructor(init?: Partial<ProjectKanbanTaskPositionsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProjectKanbanColumn implements INamedEntity, IEntity
{
    // @DataMember
    public projectId: string;

    // @DataMember
    public properties: string;

    // @DataMember
    public position: number;

    // @DataMember
    public name: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<ProjectKanbanColumn>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProjectKanbanColumnsResponse
{
    // @DataMember
    public items: ProjectKanbanColumn[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    public constructor(init?: Partial<ProjectKanbanColumnsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProjectInfo implements INamedEntity, IEntity
{
    // @DataMember
    public creator: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public created: string;

    // @DataMember
    public lastChanged: string;

    // @DataMember
    public projectImage: string;

    // @DataMember
    public mailKey: string;

    // @DataMember
    public hasLockedMembership: boolean;

    // @DataMember
    public hasKanban: boolean;

    // @DataMember
    public properties: string;

    // @DataMember
    public name: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<ProjectInfo>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProjectsResponse
{
    // @DataMember
    public items: Project[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    public constructor(init?: Partial<ProjectsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ItsTasksPreviewResponse
{
    // @DataMember
    public items: ItsTaskPreview[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    public constructor(init?: Partial<ItsTasksPreviewResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ItsTaskCustomField
{
    // @DataMember
    public customFieldId: string;

    // @DataMember
    public customField: CustomField;

    // @DataMember
    public values: ItsTaskCustomFieldStorage[];

    // @DataMember
    public id: string;

    // @DataMember
    public objectId: string;

    // @DataMember
    public value: string;

    public constructor(init?: Partial<ItsTaskCustomField>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ItsTaskTransactionCommentResponse
{
    // @DataMember
    public items: ItsTaskTransactionComment[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    public constructor(init?: Partial<ItsTaskTransactionCommentResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ItsTask implements INamedEntity, IEntity
{
    // @DataMember
    public number: number;

    // @DataMember
    public status: string;

    // @DataMember
    public priority: ItsTaskPriority;

    // @DataMember
    public authorName: string;

    // @DataMember
    public created: string;

    // @DataMember
    public assignedUserId?: string;

    // @DataMember
    public assignedUserName: string;

    // @DataMember
    public closed?: string;

    // @DataMember
    public closerId?: string;

    // @DataMember
    public closerName: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public description: string;

    // @DataMember
    public properties: string;

    // @DataMember
    public begin?: string;

    // @DataMember
    public end?: string;

    // @DataMember
    public name: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<ItsTask>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ItsTasksResponse
{
    // @DataMember
    public items: ItsTask[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    public constructor(init?: Partial<ItsTasksResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ItsTaskDetailsResponse
{
    // @DataMember
    public items: ItsTaskDetails[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    public constructor(init?: Partial<ItsTaskDetailsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EntityIntegration implements IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public entityId: string;

    // @DataMember
    public customerIntegrationId: string;

    // @DataMember
    public externalId: string;

    // @DataMember
    public settings: string;

    // @DataMember
    public integrationId: string;

    // @DataMember
    public integrationName: string;

    // @DataMember
    public integrationType: string;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<EntityIntegration>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetEntityIntegrationResponse
{
    // @DataMember
    public items: EntityIntegration[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    public constructor(init?: Partial<GetEntityIntegrationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetEntityGroupsResponse
{
    // @DataMember
    public items: EntityGroupInfo[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    public constructor(init?: Partial<GetEntityGroupsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @Route("/customFieldValues", "GET")
// @DataContract
export class GetCustomFieldValuesRequest implements IReturn<CustomFieldValuesResponse>, IProjectRequest
{
    // @DataMember
    public customFieldId: string;

    // @DataMember
    public projectId: string;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetCustomFieldValuesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new CustomFieldValuesResponse(); }
    public getTypeName() { return 'GetCustomFieldValuesRequest'; }
}

// @Route("/customFieldValues/{Id}", "GET")
// @DataContract
export class GetCustomFieldValueRequest implements IReturn<CustomFieldValue>, IProjectRequest, IBaseEntityItemRequest
{
    // @DataMember
    public projectId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetCustomFieldValueRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new CustomFieldValue(); }
    public getTypeName() { return 'GetCustomFieldValueRequest'; }
}

// @Route("/customFieldValues", "POST")
// @DataContract
export class CreateCustomFieldValueRequest implements IReturn<CustomFieldValue>, IProjectRequest, IDescription
{
    // @DataMember
    public projectId: string;

    // @DataMember(IsRequired=true)
    public entity: CustomFieldValue;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<CreateCustomFieldValueRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new CustomFieldValue(); }
    public getTypeName() { return 'CreateCustomFieldValueRequest'; }
}

// @Route("/customFieldValues/{Ids}", "DELETE")
// @DataContract
export class DeleteCustomFieldValuesRequest implements IReturn<number>, IProjectRequest, IDescription
{
    // @DataMember
    public projectId: string;

    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public ids: string[];

    public constructor(init?: Partial<DeleteCustomFieldValuesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return 0; }
    public getTypeName() { return 'DeleteCustomFieldValuesRequest'; }
}

// @Route("/customFields", "GET")
// @DataContract
export class GetCustomFieldsRequest implements IReturn<CustomFieldResponse>, IProjectRequest
{
    // @DataMember
    public projectId: string;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetCustomFieldsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new CustomFieldResponse(); }
    public getTypeName() { return 'GetCustomFieldsRequest'; }
}

// @Route("/customFields/{Id}", "GET")
// @DataContract
export class GetCustomFieldRequest implements IReturn<CustomField>, IProjectRequest, IBaseEntityItemRequest
{
    // @DataMember
    public projectId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetCustomFieldRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new CustomField(); }
    public getTypeName() { return 'GetCustomFieldRequest'; }
}

// @Route("/customFields", "POST")
// @DataContract
export class CreateCustomFieldRequest implements IReturn<CustomField>, IProjectRequest, IDescription
{
    // @DataMember
    public projectId: string;

    // @DataMember(IsRequired=true)
    public entity: CustomField;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<CreateCustomFieldRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new CustomField(); }
    public getTypeName() { return 'CreateCustomFieldRequest'; }
}

// @Route("/customFields/{Id}", "PUT")
// @DataContract
export class UpdateCustomFieldRequest implements IReturn<CustomField>, IProjectRequest, IBaseEntityItemRequest, IDescription
{
    // @DataMember
    public projectId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember(IsRequired=true)
    public entity: CustomField;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<UpdateCustomFieldRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new CustomField(); }
    public getTypeName() { return 'UpdateCustomFieldRequest'; }
}

// @Route("/customFields/{Ids}", "DELETE")
// @DataContract
export class DeleteCustomFieldRequest implements IReturn<number>, IProjectRequest, IDescription
{
    // @DataMember
    public projectId: string;

    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public ids: string[];

    public constructor(init?: Partial<DeleteCustomFieldRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return 0; }
    public getTypeName() { return 'DeleteCustomFieldRequest'; }
}

// @Route("/itsFileContent/{Link}", "GET")
// @DataContract
export class GetItsFileContentRequest implements IReturn<Object>
{
    // @DataMember
    public link: string;

    // @DataMember
    public quality: number;

    // @DataMember
    public width?: number;

    // @DataMember
    public height?: number;

    public constructor(init?: Partial<GetItsFileContentRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Object(); }
    public getTypeName() { return 'GetItsFileContentRequest'; }
}

// @Route("/file/{id}/getLink", "GET")
// @DataContract
export class GetEntityFileDownloadLinkRequest implements IReturn<string>
{
    // @DataMember
    public id: string;

    // @DataMember
    public expiredIn: number;

    public constructor(init?: Partial<GetEntityFileDownloadLinkRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return ''; }
    public getTypeName() { return 'GetEntityFileDownloadLinkRequest'; }
}

// @Route("/itsFiles/{Id}", "GET")
// @DataContract
export class GetItsFileRequest implements IReturn<ItsFile>, IProjectRequest, IBaseEntityItemRequest
{
    // @DataMember
    public projectId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetItsFileRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ItsFile(); }
    public getTypeName() { return 'GetItsFileRequest'; }
}

// @Route("/itsFiles/upload", "POST")
// @DataContract
export class CreateItsFileRequest implements IReturn<FileItem>, IProjectRequest, IDescription
{
    // @DataMember
    public name: string;

    // @DataMember
    public projectId: string;

    // @DataMember(IsRequired=true)
    public entity: FileItem;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<CreateItsFileRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new FileItem(); }
    public getTypeName() { return 'CreateItsFileRequest'; }
}

// @Route("/itsFiles/{Id}", "PUT")
// @DataContract
export class UpdateItsFileRequest implements IReturn<ItsFile>, IProjectRequest, IBaseEntityItemRequest, IDescription
{
    // @DataMember
    public projectId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember(IsRequired=true)
    public entity: ItsFile;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<UpdateItsFileRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ItsFile(); }
    public getTypeName() { return 'UpdateItsFileRequest'; }
}

// @Route("/itsFiles/{Ids}", "DELETE")
// @DataContract
export class DeleteItsFilesRequest implements IReturn<number>, IProjectRequest, IDescription
{
    // @DataMember
    public projectId: string;

    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public ids: string[];

    public constructor(init?: Partial<DeleteItsFilesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return 0; }
    public getTypeName() { return 'DeleteItsFilesRequest'; }
}

// @Route("/itsFiles", "GET")
// @DataContract
export class GetItsFilesRequest implements IReturn<ItsFilesResponse>, IProjectRequest
{
    // @DataMember
    public userId?: string;

    // @DataMember
    public from?: string;

    // @DataMember
    public to?: string;

    // @DataMember
    public projectId: string;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetItsFilesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ItsFilesResponse(); }
    public getTypeName() { return 'GetItsFilesRequest'; }
}

// @Route("/projectUsers/{ProjectId}", "GET")
// @DataContract
export class GetProjectUsersRequest implements IReturn<ProjectUsersResponse>, IProjectRequest
{
    // @DataMember
    public customerId?: string;

    // @DataMember
    public projectId: string;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetProjectUsersRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ProjectUsersResponse(); }
    public getTypeName() { return 'GetProjectUsersRequest'; }
}

// @Route("/availableUsers", "GET")
// @DataContract
export class GetAvailableUsersRequest implements IReturn<AvailableUsersResponse>, IProjectRequest
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public projectId: string;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetAvailableUsersRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new AvailableUsersResponse(); }
    public getTypeName() { return 'GetAvailableUsersRequest'; }
}

// @Route("/projectUsers/{Id}", "GET")
// @DataContract
export class GetProjectUserRequest implements IReturn<ProjectUserInfo>, IBaseEntityItemRequest
{
    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetProjectUserRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ProjectUserInfo(); }
    public getTypeName() { return 'GetProjectUserRequest'; }
}

// @Route("/projectUsers/{UserId}", "POST")
// @DataContract
export class AddProjectUserRequest implements IReturn<ProjectUserInfo>, IProjectRequest
{
    // @DataMember(IsRequired=true)
    public userId: string;

    // @DataMember(IsRequired=true)
    public projectId: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    public constructor(init?: Partial<AddProjectUserRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ProjectUserInfo(); }
    public getTypeName() { return 'AddProjectUserRequest'; }
}

// @Route("/projectUsers", "POST")
// @DataContract
export class AddProjectUsersRequest implements IReturn<ProjectUserInfo[]>, IProjectRequest
{
    // @DataMember(IsRequired=true)
    public userIds: string[];

    // @DataMember(IsRequired=true)
    public projectId: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    public constructor(init?: Partial<AddProjectUsersRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Array<ProjectUserInfo>(); }
    public getTypeName() { return 'AddProjectUsersRequest'; }
}

// @Route("/projectUsers/{Id}/roles", "PUT")
// @DataContract
export class UpdateProjectUserRolesRequest implements IReturn<ProjectUserInfo>, IProjectRequest, IBaseEntityItemRequest, IDescription
{
    // @DataMember
    public projectId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember(IsRequired=true)
    public entity: ProjectUserInfo;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<UpdateProjectUserRolesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ProjectUserInfo(); }
    public getTypeName() { return 'UpdateProjectUserRolesRequest'; }
}

// @Route("/projectUsers/{Ids}", "DELETE")
// @DataContract
export class DeleteProjectUsersRequest implements IReturn<number>, IProjectRequest, IDescription
{
    // @DataMember
    public projectId: string;

    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public ids: string[];

    public constructor(init?: Partial<DeleteProjectUsersRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return 0; }
    public getTypeName() { return 'DeleteProjectUsersRequest'; }
}

// @Route("/currentUser/permissions")
// @DataContract
export class GetCurrentUserPermissions implements IReturn<Permission[]>, IProjectRequest
{
    // @DataMember
    public projectId: string;

    public constructor(init?: Partial<GetCurrentUserPermissions>) { (Object as any).assign(this, init); }
    public createResponse() { return new Array<Permission>(); }
    public getTypeName() { return 'GetCurrentUserPermissions'; }
}

// @Route("/role/Permissions")
// @DataContract
export class GetRolePermissions implements IReturn<Permission[]>
{
    // @DataMember
    public role: Roles;

    public constructor(init?: Partial<GetRolePermissions>) { (Object as any).assign(this, init); }
    public createResponse() { return new Array<Permission>(); }
    public getTypeName() { return 'GetRolePermissions'; }
}

// @Route("/projectCustomers/roles", "GET")
// @DataContract
export class GetProjectCustomerRolesRequest implements IReturn<Roles[]>, IProjectRequest
{
    // @DataMember
    public projectId: string;

    // @DataMember
    public customerId: string;

    public constructor(init?: Partial<GetProjectCustomerRolesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Array<Roles>(); }
    public getTypeName() { return 'GetProjectCustomerRolesRequest'; }
}

// @Route("/projectCustomers", "GET")
// @DataContract
export class GetProjectCustomersRequest implements IReturn<ProjectCustomerResponse>, IProjectRequest
{
    // @DataMember
    public projectId: string;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetProjectCustomersRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ProjectCustomerResponse(); }
    public getTypeName() { return 'GetProjectCustomersRequest'; }
}

// @Route("/projectCustomers/{Id}", "GET")
// @DataContract
export class GetProjectCustomerRequest implements IReturn<ProjectCustomer>, IBaseEntityItemRequest
{
    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetProjectCustomerRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ProjectCustomer(); }
    public getTypeName() { return 'GetProjectCustomerRequest'; }
}

// @Route("/projectCustomers", "POST")
// @DataContract
export class AddCustomerToProjectRequest implements IReturn<ProjectCustomer>, IProjectRequest, IDescription
{
    // @DataMember
    public projectId: string;

    // @DataMember(IsRequired=true)
    public entity: ProjectCustomer;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<AddCustomerToProjectRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ProjectCustomer(); }
    public getTypeName() { return 'AddCustomerToProjectRequest'; }
}

// @Route("/projectCustomers", "DELETE")
// @DataContract
export class DeleteCustomerFromProjectRequest implements IReturn<number>, IProjectRequest, IDescription
{
    // @DataMember
    public projectId: string;

    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public ids: string[];

    public constructor(init?: Partial<DeleteCustomerFromProjectRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return 0; }
    public getTypeName() { return 'DeleteCustomerFromProjectRequest'; }
}

// @Route("/projectKanbanTaskPosition/{Id}", "GET")
// @DataContract
export class GetProjectKanbanTaskPositionRequest implements IReturn<ProjectKanbanTaskPosition>, IProjectRequest, IBaseEntityItemRequest
{
    // @DataMember
    public projectId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetProjectKanbanTaskPositionRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ProjectKanbanTaskPosition(); }
    public getTypeName() { return 'GetProjectKanbanTaskPositionRequest'; }
}

export class GetProjectKanbanTaskPositionsRequest implements IReturn<ProjectKanbanTaskPositionsResponse>, IProjectRequest
{
    // @DataMember
    public showClosed: boolean;

    // @DataMember
    public projectKanbanColumnId?: string;

    // @DataMember
    public userId?: string;

    // @DataMember
    public projectId: string;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetProjectKanbanTaskPositionsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ProjectKanbanTaskPositionsResponse(); }
    public getTypeName() { return 'GetProjectKanbanTaskPositionsRequest'; }
}

// @Route("/projectKanbanTaskPosition", "PUT")
// @DataContract
export class UpdateProjectKanbanTaskPositionRequest implements IReturn<ProjectKanbanTaskPosition>, IProjectRequest, IBaseEntityItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public projectKanbanColumnId: string;

    // @DataMember(IsRequired=true)
    public taskId: string;

    // @DataMember
    public insertAfterTaskId?: string;

    // @DataMember
    public projectId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember(IsRequired=true)
    public entity: ProjectKanbanTaskPosition;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<UpdateProjectKanbanTaskPositionRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ProjectKanbanTaskPosition(); }
    public getTypeName() { return 'UpdateProjectKanbanTaskPositionRequest'; }
}

// @Route("/projectKanbanTaskPositions", "POST")
// @DataContract
export class CreateProjectKanbanTaskPositionRequest implements IReturn<ProjectKanbanTaskPosition>, IProjectRequest, IDescription
{
    // @DataMember
    public projectId: string;

    // @DataMember(IsRequired=true)
    public entity: ProjectKanbanTaskPosition;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<CreateProjectKanbanTaskPositionRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ProjectKanbanTaskPosition(); }
    public getTypeName() { return 'CreateProjectKanbanTaskPositionRequest'; }
}

// @Route("/projectKanbanColumns/{Id}", "GET")
// @DataContract
export class GetProjectKanbanColumnRequest implements IReturn<ProjectKanbanColumn>, IBaseEntityItemRequest
{
    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetProjectKanbanColumnRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ProjectKanbanColumn(); }
    public getTypeName() { return 'GetProjectKanbanColumnRequest'; }
}

// @Route("/projectKanbanColumns", "POST")
// @DataContract
export class CreateProjectKanbanColumnRequest implements IReturn<ProjectKanbanColumn>, IProjectRequest, IDescription
{
    // @DataMember
    public projectId: string;

    // @DataMember(IsRequired=true)
    public entity: ProjectKanbanColumn;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<CreateProjectKanbanColumnRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ProjectKanbanColumn(); }
    public getTypeName() { return 'CreateProjectKanbanColumnRequest'; }
}

// @Route("/projectKanbanColumns/{Id}", "PUT")
// @DataContract
export class UpdateProjectKanbanColumnRequest implements IReturn<ProjectKanbanColumn>, IProjectRequest, IBaseEntityItemRequest, IDescription
{
    // @DataMember
    public projectId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember(IsRequired=true)
    public entity: ProjectKanbanColumn;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<UpdateProjectKanbanColumnRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ProjectKanbanColumn(); }
    public getTypeName() { return 'UpdateProjectKanbanColumnRequest'; }
}

// @Route("/projectKanbanColumns/{Ids}", "DELETE")
// @DataContract
export class DeleteProjectKanbanColumnsRequest implements IReturn<number>, IProjectRequest, IDescription
{
    // @DataMember
    public projectId: string;

    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public ids: string[];

    public constructor(init?: Partial<DeleteProjectKanbanColumnsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return 0; }
    public getTypeName() { return 'DeleteProjectKanbanColumnsRequest'; }
}

// @Route("/projectKanbanColumns", "GET")
// @DataContract
export class GetProjectKanbanColumnsRequest implements IReturn<ProjectKanbanColumnsResponse>, IProjectRequest
{
    // @DataMember
    public projectId: string;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetProjectKanbanColumnsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ProjectKanbanColumnsResponse(); }
    public getTypeName() { return 'GetProjectKanbanColumnsRequest'; }
}

// @Route("/projects/{Id}", "GET")
// @DataContract
export class GetProjectRequest implements IReturn<Project>, IBaseEntityItemRequest
{
    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetProjectRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Project(); }
    public getTypeName() { return 'GetProjectRequest'; }
}

// @Route("/projectInfo/{Id}", "GET")
// @DataContract
export class GetProjectInfoRequest implements IReturn<ProjectInfo>, IBaseEntityItemRequest
{
    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetProjectInfoRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ProjectInfo(); }
    public getTypeName() { return 'GetProjectInfoRequest'; }
}

// @Route("/projects", "POST")
// @DataContract
export class CreateProjectRequest implements IReturn<Project>, IDescription
{
    // @DataMember
    public createDefaultKanbans: boolean;

    // @DataMember(IsRequired=true)
    public entity: Project;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<CreateProjectRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Project(); }
    public getTypeName() { return 'CreateProjectRequest'; }
}

// @Route("/projects/{Id}", "PUT")
// @DataContract
export class UpdateProjectRequest implements IReturn<Project>, IBaseEntityItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember(IsRequired=true)
    public entity: Project;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<UpdateProjectRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Project(); }
    public getTypeName() { return 'UpdateProjectRequest'; }
}

// @Route("/projects/{Ids}", "DELETE")
// @DataContract
export class DeleteProjectsRequest implements IReturn<number>, IDescription
{
    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public ids: string[];

    public constructor(init?: Partial<DeleteProjectsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return 0; }
    public getTypeName() { return 'DeleteProjectsRequest'; }
}

// @Route("/projects", "GET")
// @DataContract
export class GetProjectsRequest implements IReturn<ProjectsResponse>
{
    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetProjectsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ProjectsResponse(); }
    public getTypeName() { return 'GetProjectsRequest'; }
}

// @Route("/kanbanTasksPreview", "GET")
// @DataContract
export class GetKanbanTasksPreviewRequest implements IReturn<ItsTasksPreviewResponse>, IProjectRequest
{
    // @DataMember
    public kanbanColumnId: string;

    // @DataMember
    public assignedUserId?: string;

    // @DataMember
    public minPriority?: number;

    // @DataMember
    public withStatus: string;

    // @DataMember
    public projectId: string;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetKanbanTasksPreviewRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ItsTasksPreviewResponse(); }
    public getTypeName() { return 'GetKanbanTasksPreviewRequest'; }
}

// @Route("/itsTasksPreview", "GET")
// @DataContract
export class GetItsTasksPreviewRequest implements IReturn<ItsTasksPreviewResponse>, IProjectRequest
{
    // @DataMember
    public assignedUserId?: string;

    // @DataMember
    public minPriority?: number;

    // @DataMember
    public withStatus: string;

    // @DataMember
    public projectId: string;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetItsTasksPreviewRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ItsTasksPreviewResponse(); }
    public getTypeName() { return 'GetItsTasksPreviewRequest'; }
}

// @Route("/itsTaskGroupPreview/assigned", "GET")
// @DataContract
export class GetItsTasksAssignedGroupsPreviewRequest implements IReturn<ItsTaskAssignedGroupPreview[]>, IProjectRequest
{
    // @DataMember(IsRequired=true)
    public projectId: string;

    // @DataMember
    public assignedUserId?: string;

    public constructor(init?: Partial<GetItsTasksAssignedGroupsPreviewRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Array<ItsTaskAssignedGroupPreview>(); }
    public getTypeName() { return 'GetItsTasksAssignedGroupsPreviewRequest'; }
}

// @Route("/itsTaskGroupPreview/authored", "GET")
// @DataContract
export class GetItsTasksAuthoredGroupsPreviewRequest implements IReturn<ItsTaskAuthoredGroupPreview[]>, IProjectRequest
{
    // @DataMember(IsRequired=true)
    public projectId: string;

    // @DataMember
    public assignedUserId?: string;

    public constructor(init?: Partial<GetItsTasksAuthoredGroupsPreviewRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Array<ItsTaskAuthoredGroupPreview>(); }
    public getTypeName() { return 'GetItsTasksAuthoredGroupsPreviewRequest'; }
}

// @Route("/itsTaskGroupPreview/status", "GET")
// @DataContract
export class GetItsTasksStatusGroupsPreviewRequest implements IReturn<ItsTaskStatusGroupPreview[]>, IProjectRequest
{
    // @DataMember(IsRequired=true)
    public projectId: string;

    // @DataMember
    public assignedUserId?: string;

    public constructor(init?: Partial<GetItsTasksStatusGroupsPreviewRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Array<ItsTaskStatusGroupPreview>(); }
    public getTypeName() { return 'GetItsTasksStatusGroupsPreviewRequest'; }
}

// @Route("/itsTaskGroupPreview/priority", "GET")
// @DataContract
export class GetItsTasksPriorityGroupsPreviewRequest implements IReturn<ItsTaskPriorityGroupPreview[]>, IProjectRequest
{
    // @DataMember(IsRequired=true)
    public projectId: string;

    // @DataMember
    public assignedUserId?: string;

    public constructor(init?: Partial<GetItsTasksPriorityGroupsPreviewRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Array<ItsTaskPriorityGroupPreview>(); }
    public getTypeName() { return 'GetItsTasksPriorityGroupsPreviewRequest'; }
}

// @Route("/availableTaskCustomFields/{ProjectId}", "GET")
// @DataContract
export class GetAvailableItsTaskCustomFields implements IReturn<CustomField[]>, IProjectRequest
{
    // @DataMember
    public projectId: string;

    public constructor(init?: Partial<GetAvailableItsTaskCustomFields>) { (Object as any).assign(this, init); }
    public createResponse() { return new Array<CustomField>(); }
    public getTypeName() { return 'GetAvailableItsTaskCustomFields'; }
}

// @Route("/itsTaskCustomFields/{TaskId}", "GET")
// @DataContract
export class GetItsTaskCustomFields implements IReturn<ItsTaskCustomField[]>, IProjectRequest
{
    // @DataMember
    public taskId: string;

    // @DataMember
    public projectId: string;

    public constructor(init?: Partial<GetItsTaskCustomFields>) { (Object as any).assign(this, init); }
    public createResponse() { return new Array<ItsTaskCustomField>(); }
    public getTypeName() { return 'GetItsTaskCustomFields'; }
}

// @Route("/itsTaskCustomFields/{TaskId}", "PUT")
// @DataContract
export class UpdateItsTaskCustomFieldsRequest implements IReturn<ItsTaskCustomField[]>, IProjectRequest
{
    // @DataMember
    public taskId: string;

    // @DataMember
    public customFields: ItsTaskCustomField[];

    // @DataMember
    public projectId: string;

    public constructor(init?: Partial<UpdateItsTaskCustomFieldsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Array<ItsTaskCustomField>(); }
    public getTypeName() { return 'UpdateItsTaskCustomFieldsRequest'; }
}

// @Route("/itsTaskCustomField/{Id}", "PUT")
// @DataContract
export class UpdateItsTaskCustomFieldRequest implements IReturn<ItsTaskCustomField>, IProjectRequest
{
    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember(IsRequired=true)
    public entity: ItsTaskCustomField;

    // @DataMember
    public description: string;

    // @DataMember
    public projectId: string;

    public constructor(init?: Partial<UpdateItsTaskCustomFieldRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ItsTaskCustomField(); }
    public getTypeName() { return 'UpdateItsTaskCustomFieldRequest'; }
}

// @Route("/itsTaskTransactionComments", "GET")
// @DataContract
export class GetItsTaskTransactionCommentsRequest implements IReturn<ItsTaskTransactionCommentResponse>, IProjectRequest
{
    // @DataMember
    public transactionId: string;

    // @DataMember
    public projectId: string;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetItsTaskTransactionCommentsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ItsTaskTransactionCommentResponse(); }
    public getTypeName() { return 'GetItsTaskTransactionCommentsRequest'; }
}

// @Route("/itsTaskTransactionComments/{Id}", "GET")
// @DataContract
export class GetItsTaskTransactionCommentRequest implements IReturn<ItsTaskTransactionComment>, IProjectRequest, IBaseEntityItemRequest
{
    // @DataMember
    public projectId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetItsTaskTransactionCommentRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ItsTaskTransactionComment(); }
    public getTypeName() { return 'GetItsTaskTransactionCommentRequest'; }
}

// @Route("/itsTaskTransactionComments/{TaskId}", "POST")
// @DataContract
export class CreateItsTaskTransactionCommentRequest implements IReturn<ItsTaskTransactionComment>, IProjectRequest, IDescription
{
    // @DataMember
    public taskId: string;

    // @DataMember
    public projectId: string;

    // @DataMember(IsRequired=true)
    public entity: ItsTaskTransactionComment;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<CreateItsTaskTransactionCommentRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ItsTaskTransactionComment(); }
    public getTypeName() { return 'CreateItsTaskTransactionCommentRequest'; }
}

// @Route("/itsTaskTransactionComments/{Id}", "PUT")
// @DataContract
export class UpdateItsTaskTransactionCommentRequest implements IReturn<ItsTaskTransactionComment>, IProjectRequest, IBaseEntityItemRequest, IDescription
{
    // @DataMember
    public projectId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember(IsRequired=true)
    public entity: ItsTaskTransactionComment;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<UpdateItsTaskTransactionCommentRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ItsTaskTransactionComment(); }
    public getTypeName() { return 'UpdateItsTaskTransactionCommentRequest'; }
}

// @Route("/itsTaskTransactionComments/{Ids}", "DELETE")
// @DataContract
export class DeleteItsTaskTransactionCommentRequest implements IReturn<number>, IProjectRequest, IDescription
{
    // @DataMember
    public projectId: string;

    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public ids: string[];

    public constructor(init?: Partial<DeleteItsTaskTransactionCommentRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return 0; }
    public getTypeName() { return 'DeleteItsTaskTransactionCommentRequest'; }
}

// @Route("/itsTaskTransactions", "GET")
// @DataContract
export class GetItsTaskTransactions implements IReturn<ItsTaskTransaction[]>, IProjectRequest
{
    // @DataMember(IsRequired=true)
    public taskId: string;

    // @DataMember
    public projectId: string;

    public constructor(init?: Partial<GetItsTaskTransactions>) { (Object as any).assign(this, init); }
    public createResponse() { return new Array<ItsTaskTransaction>(); }
    public getTypeName() { return 'GetItsTaskTransactions'; }
}

// @Route("/detailedItsTaskTransactions", "GET")
// @DataContract
export class GetDetailedItsTaskTransactions implements IReturn<DetailedItsTaskTransaction[]>, IProjectRequest
{
    // @DataMember(IsRequired=true)
    public taskId: string;

    // @DataMember
    public projectId: string;

    public constructor(init?: Partial<GetDetailedItsTaskTransactions>) { (Object as any).assign(this, init); }
    public createResponse() { return new Array<DetailedItsTaskTransaction>(); }
    public getTypeName() { return 'GetDetailedItsTaskTransactions'; }
}

// @Route("/itsTask/{Id}", "GET")
// @DataContract
export class GetItsTaskRequest implements IReturn<ItsTask>, IProjectRequest, IBaseEntityItemRequest
{
    // @DataMember
    public projectId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetItsTaskRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ItsTask(); }
    public getTypeName() { return 'GetItsTaskRequest'; }
}

// @Route("/itsTask", "POST")
// @DataContract
export class CreateItsTaskRequest implements IReturn<ItsTask>, IProjectRequest, IDescription
{
    // @DataMember
    public projectIds: string[];

    // @DataMember(IsRequired=true)
    public projectKanbanColumnId: string;

    // @DataMember
    public customFields: ItsTaskCustomField[];

    // @DataMember
    public projectId: string;

    // @DataMember(IsRequired=true)
    public entity: ItsTask;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<CreateItsTaskRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ItsTask(); }
    public getTypeName() { return 'CreateItsTaskRequest'; }
}

// @Route("/itsTask/{Id}", "PUT")
// @DataContract
export class UpdateItsTaskRequest implements IReturn<ItsTask>, IProjectRequest, IBaseEntityItemRequest, IDescription
{
    // @DataMember
    public projectId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember(IsRequired=true)
    public entity: ItsTask;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<UpdateItsTaskRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ItsTask(); }
    public getTypeName() { return 'UpdateItsTaskRequest'; }
}

// @Route("/itsTask/{Ids}", "DELETE")
// @DataContract
export class DeleteItsTasksRequest implements IReturn<number>, IProjectRequest, IDescription
{
    // @DataMember
    public projectId: string;

    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public ids: string[];

    public constructor(init?: Partial<DeleteItsTasksRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return 0; }
    public getTypeName() { return 'DeleteItsTasksRequest'; }
}

// @Route("/itsTask", "GET")
// @DataContract
export class GetItsTasksRequest implements IReturn<ItsTasksResponse>, IProjectRequest
{
    // @DataMember
    public assignedUserId?: string;

    // @DataMember
    public projectKanbanColumnId?: string;

    // @DataMember
    public minPriority: ItsTaskPriority;

    // @DataMember
    public withStatus: string;

    // @DataMember
    public projectId: string;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetItsTasksRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ItsTasksResponse(); }
    public getTypeName() { return 'GetItsTasksRequest'; }
}

// @Route("/itsTasks/currentUser", "GET")
// @DataContract
export class GetCurrentUserAssignedTasksRequest implements IReturn<ItsTaskDetailsResponse>
{
    // @DataMember
    public minPriority: ItsTaskPriority;

    // @DataMember
    public withStatus: string;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetCurrentUserAssignedTasksRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ItsTaskDetailsResponse(); }
    public getTypeName() { return 'GetCurrentUserAssignedTasksRequest'; }
}

// @Route("/validateToken", "GET")
// @DataContract
export class ValidateItsTokenRequest implements IReturn<boolean>
{
    // @DataMember
    public application: ApplicationInfo;

    public constructor(init?: Partial<ValidateItsTokenRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return false; }
    public getTypeName() { return 'ValidateItsTokenRequest'; }
}

// @Route("/healthStatus", "GET")
// @DataContract
export class GetHealthStatusRequest implements IReturn<string>
{

    public constructor(init?: Partial<GetHealthStatusRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return ''; }
    public getTypeName() { return 'GetHealthStatusRequest'; }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entities", "GET")
// @DataContract
export class GetEntityIntegrationsRequest implements IReturn<GetEntityIntegrationResponse>, ICustomerIntegrationIdRequest, ICustomerItemRequest
{
    // @DataMember
    public customerIntegrationId: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public entityGroups: string[];

    // @DataMember
    public itemsIds: string[];

    // @DataMember
    public onlyOwnedItems: boolean;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetEntityIntegrationsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEntityIntegrationResponse(); }
    public getTypeName() { return 'GetEntityIntegrationsRequest'; }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entity/{id}", "GET")
// @DataContract
export class GetEntityIntegrationRequest implements IReturn<EntityIntegration>, ICustomerIntegrationIdRequest, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember
    public customerIntegrationId: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetEntityIntegrationRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new EntityIntegration(); }
    public getTypeName() { return 'GetEntityIntegrationRequest'; }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entities", "POST")
// @DataContract
export class CreateEntityIntegrationRequest implements IReturn<EntityIntegration>, ICustomerIntegrationIdRequest, ICustomerItemRequest, IDescription
{
    // @DataMember
    public customerIntegrationId: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public updateEntityGroups: boolean;

    // @DataMember
    public entityGroups: string[];

    // @DataMember(IsRequired=true)
    public entity: EntityIntegration;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<CreateEntityIntegrationRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new EntityIntegration(); }
    public getTypeName() { return 'CreateEntityIntegrationRequest'; }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entity/{id}", "PUT")
// @DataContract
export class UpdateEntityIntegrationRequest implements IReturn<EntityIntegration>, ICustomerIntegrationIdRequest, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
    // @DataMember
    public customerIntegrationId: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public updateEntityGroups: boolean;

    // @DataMember
    public entityGroups: string[];

    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember(IsRequired=true)
    public entity: EntityIntegration;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<UpdateEntityIntegrationRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new EntityIntegration(); }
    public getTypeName() { return 'UpdateEntityIntegrationRequest'; }
}

// @Route("/entity/{id}/properties", "GET")
// @DataContract
export class GetEntityPropertiesRequest implements IReturn<any>, IBaseEntityItemRequest
{
    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetEntityPropertiesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return {}; }
    public getTypeName() { return 'GetEntityPropertiesRequest'; }
}

// @Route("/entity/{id}/properties", "PUT")
// @DataContract
export class UpdateEntityPropertiesRequest implements IReturn<any>
{
    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember
    public description: string;

    // @DataMember
    public entity: { [index: string]: string; };

    public constructor(init?: Partial<UpdateEntityPropertiesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return {}; }
    public getTypeName() { return 'UpdateEntityPropertiesRequest'; }
}

// @Route("/entity/{id}/property/{property}", "GET")
// @DataContract
export class GetEntityPropertyRequest implements IReturn<string>
{
    // @DataMember
    public property: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetEntityPropertyRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return ''; }
    public getTypeName() { return 'GetEntityPropertyRequest'; }
}

// @Route("/entity/{id}/property/{property}", "PUT")
// @DataContract
export class UpdateEntityPropertyRequest implements IReturn<string>
{
    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember
    public description: string;

    // @DataMember
    public property: string;

    // @DataMember
    public value: string;

    public constructor(init?: Partial<UpdateEntityPropertyRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return ''; }
    public getTypeName() { return 'UpdateEntityPropertyRequest'; }
}

// @Route("/customer/{customerId}/entities/property/{property}", "GET")
// @DataContract
export class GetEntitiesPropertyRequest implements IReturn<any>
{
    // @DataMember
    public property: string;

    // @DataMember
    public filter: string;

    // @DataMember
    public customerId: string;

    public constructor(init?: Partial<GetEntitiesPropertyRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return {}; }
    public getTypeName() { return 'GetEntitiesPropertyRequest'; }
}

// @Route("/customer/{customerId}/entity/{Id}/groups", "GET")
// @DataContract
export class GetItemEntityGroupsRequest implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
    // @DataMember
    public id: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public entityGroups: string[];

    // @DataMember
    public itemsIds: string[];

    // @DataMember
    public onlyOwnedItems: boolean;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetItemEntityGroupsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEntityGroupsResponse(); }
    public getTypeName() { return 'GetItemEntityGroupsRequest'; }
}

// @Route("/auth")
// @Route("/auth/{provider}")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=16)
    public useTokenCookie?: boolean;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AuthenticateResponse(); }
    public getTypeName() { return 'Authenticate'; }
}

