import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { Inject, NgModule } from '@angular/core';

import localeEn from '@angular/common/locales/en';
import localeEnExtra from '@angular/common/locales/extra/en';
import localeRu from '@angular/common/locales/ru';
import localeRuExtra from '@angular/common/locales/extra/ru';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import {
  BaseModule,
  DialogModule,
  FlashModule,
  HubSharedModule,
} from 'msu-its-web-common-lib';
import { CommonModule, registerLocaleData } from '@angular/common';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NavbarComponent } from './shared/components/navbar/navbar.component';
import { SelectLangComponent } from './shared/components/navbar/select-lang.component';
import { SidenavComponent } from './shared/components/sidenav/sidenav.component';
import { JsonServiceClient } from '@servicestack/client';
import { LayoutGapStyleBuilder } from '@angular/flex-layout';
import { CustomLayoutGapStyleBuilder } from './shared/services/custom-layout-gap-style-builder';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { PermissionService } from './shared/services/permission.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const CUSTOM_MOMENT_FORMATS = {
  parse: {
    dateInput: ', HH:mm',
  },
  display: {
    dateInput: 'DD.MM.YYYY, HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SelectLangComponent,
    SidenavComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HammerModule,
    HttpClientModule,
    CommonModule,

    SharedModule.forRoot(),

    FlashModule,
    DialogModule,
    BaseModule,
    HubSharedModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) =>
          new TranslateHttpLoader(http, './assets/i18n/'), // ,'.json?t=' + version.hash
        deps: [HttpClient],
      },
    }),

    AppRoutingModule,
  ],
  providers: [
    {
      provide: LayoutGapStyleBuilder, // when default is requested
      useClass: CustomLayoutGapStyleBuilder, // provide instead custom builder
    },
    { provide: JsonServiceClient, useValue: new JsonServiceClient('/') },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    PermissionService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private _translate: TranslateService,
    _dateAdapter: DateAdapter<any>,
    @Inject('APP_ENVIRONMENT')
    _appEnv
  ) {
    registerLocaleData(localeEn, 'en', localeEnExtra);
    registerLocaleData(localeRu, 'ru', localeRuExtra);
    // _translate.addLangs(_appEnv.rmm.allowLangs);
    // _translate.setDefaultLang(_appEnv.rmm.defaultLang);
    _dateAdapter.setLocale('ru');
    _translate.use('ru');
  }
}
