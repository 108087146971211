<h4 mat-dialog-title style="margin-bottom: 16px;">
 {{'COMMON.SELECT_LANGUAGE'|translate}}
</h4>

<mat-dialog-content>
  <mat-action-list>
    <button
      *ngFor="let l of translate.langs"
      mat-list-item
      class="mat-hover"
      (click)="setLang(l)"
    >
      <img
        mat-list-icon
        class="flag"
        [src]="'/assets/images/langs/' + l + '.png'"
      />
      {{ langs[l] }}
    </button>
  </mat-action-list>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button cdkFocusInitial [mat-dialog-close]="false"> {{'BUTTONS.CLOSE'|translate}}</button>
</mat-dialog-actions>
