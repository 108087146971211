import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, LoginComponent } from 'msu-its-web-common-lib';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { SidenavComponent } from './shared/components/sidenav/sidenav.component';

const routes: Routes = [
  // { path: "", redirectTo: "home", pathMatch: "full" },
  {
    path: '',
    component: SidenavComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'project/:projectId',
        loadChildren: () =>
          import('./modules/project/project.module').then(
            (m) => m.ProjectModule
          ),
      },
      {
        path: 'project/:projectId/task/:taskId',
        loadChildren: () =>
          import('./modules/task/task.module').then((m) => m.TaskModule),
      },
      {
        path: 'home',
        loadChildren: () =>
          import('./modules/main-page/main-page.module').then(
            (m) => m.MainPageModule
          ),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./modules/settings/settings.module').then(
            (m) => m.SettingsModule
          ),
      },
      // {
      //   path: 'task/:taskId',
      //   loadChildren: () =>
      //     import('./modules/task/task.module').then((m) => m.TaskModule),
      // },
    ],
  },
  { path: 'login', component: LoginComponent },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      relativeLinkResolution: 'legacy',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
