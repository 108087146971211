import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

const LANG = 'lang';
const SIDENAV_EXPANDED = 'sidenav_expanded';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor() {}
  public updateSidenav = new Subject();

  public get lang(): string {
    return localStorage.getItem(LANG);
  }
  public set lang(value: string) {
    localStorage.setItem(LANG, value);
  }

  public get sidenavExpanded(): boolean {
    return localStorage.getItem(SIDENAV_EXPANDED) !== 'false';
  }
  public set sidenavExpanded(value: boolean) {
    localStorage.setItem(SIDENAV_EXPANDED, value ? null : 'false');
  }
}
