import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TokenService } from 'msu-its-web-common-lib';
import { Subject } from 'rxjs';
import { GetCurrentUserPermissions, Permission } from 'src/app/dto/api.dtos';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class PermissionService {
  private _permissions: Permission[];
  private _projectId: string;
  public changedProjectId = new Subject<string>();
  public permissionCheck = new Subject<boolean>();
  public hasPermArray: boolean;
  constructor(
    private _apiService: ApiService,
    private _tokenService: TokenService
  ) {
    this.changedProjectId.subscribe((res) => {
      this._projectId = res;
      this.getCurrentUserPermissions();
    });
    this._tokenService.tokenChanged.subscribe(() => {
      this.getCurrentUserPermissions();
    });
    // this._permissions
    //   ? this.permissionCheck.next(false)
    //   : this.getCurrentUserPermissions('_permissions');
  }

  getCurrentUserPermissions() {
    if (this._projectId !== 'settings') {
      const request = new GetCurrentUserPermissions();
      request.projectId = this._projectId;
      this._apiService.get(request).subscribe((res) => {
        this._permissions = res;
        this.permissionCheck.next(true);
        console.log(this._permissions);
      });
    }
  }

  hasPermissions(expectedPermissions: string) {
    if (!(expectedPermissions && expectedPermissions.length)) {
      return true;
    }

    const permissions = this._permissions as Permission[];

    if (this._tokenService.hasPermissions(['system.manage'])) {
      return true;
    }
    if (this._projectId === 'settings') {
      return false;
    }

    return permissions.find((item) => item.id === expectedPermissions)
      ? true
      : false;
  }
}
