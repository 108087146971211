import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { from, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const updateEnvironmentFromLocalStorage = () => {
  const hubApiUrl = localStorage.getItem('hubApiUrl');
  if (hubApiUrl && environment.hub) environment.hub.apiUrl = hubApiUrl;
};

const updateEnvironmentFromSchema = () => {
  const host = window.location.host;
  const protocol = window.location.protocol;

  Object.keys(environment).forEach((m) => {
    if (m === 'hub') {
      const module: any = environment[m];
      if (module && typeof module == 'object' && 'apiUrl' in module) {
        if (host === 'dev.support.msu24.ru') {
          module.apiUrl = `${protocol}//${m}.dev.its.msu24.ru`;
        } else if (host === 'stage.support.msu24.ru') {
          module.apiUrl = `${protocol}//${m}.stage.its.msu24.ru`;
        } else if (host === 'krsk.support.msu24.ru') {
          module.apiUrl = `${protocol}//${m}.krsk.its.msu24.ru`;
        }
      }
    }
  });
};
const updateEnvironmentFromConfig = () => {
  return from(fetch('./assets/config.json')).pipe(
    switchMap((response) => response.json()),
    catchError((e) => of(null)),
    tap((config) => {
      if (config) {
        console.log('config', config);

        for (let key of Object.keys(config)) {
          if (!environment[key]) {
            continue;
          }
          const configItem = config[key];
          if (configItem && typeof configItem == 'object') {
            for (let innerKey of Object.keys(configItem)) {
              environment[key][innerKey] = configItem[innerKey];
            }
          } else {
            environment[key] = configItem;
          }
        }
      }
    })
  );
};

if (environment.production) {
  enableProdMode();
}
const providers = [
  {
    provide: 'APP_ENVIRONMENT',
    useValue: environment,
    deps: [],
  },
];
if (location.hostname != 'localhost') {
  updateEnvironmentFromSchema();
}


updateEnvironmentFromConfig()
  .pipe(
    switchMap(() => {
      updateEnvironmentFromLocalStorage();
      console.log('environment', environment);
      return platformBrowserDynamic(providers).bootstrapModule(AppModule);
    })
  )
  .toPromise();
