<app-navbar></app-navbar>

<mat-sidenav-container hasBackdrop="false">
  <mat-sidenav
    opened="true"
    mode="over"
    [@sidenavAnimation]="expanded ? 'expanded' : 'collapsed'"
  >
    <mat-nav-list>
      <ng-container *ngFor="let item of sidenavItemsUtils">
        <a
          mat-list-item
          #listItem
          [routerLink]="item.path"
          routerLinkActive="active"
          (click)="click(item)"
        >
          <mat-icon matListIcon>{{ item.icon }}</mat-icon>

          <p matLine [title]="item.name">
            {{ item.name | translate }}
          </p>
        </a>
      </ng-container>
      <mat-divider></mat-divider>
      <ng-container *ngFor="let item of sidenavItems">
        <a
          mat-list-item
          #listItem
          [routerLink]="item.group ? [] : [item.path]"
          routerLinkActive="active"
          (click)="click(item)"
        >
          <mat-icon matListIcon>{{ item.icon }}</mat-icon>

          <p matLine [title]="item.name">
            {{ item.name | translate }}
          </p>
        </a>
      </ng-container>
      <mat-divider></mat-divider>
      <ng-container *ngFor="let item of sidenavItemsSetting">
        <a
          mat-list-item
          #listItem
          [routerLink]="item.path"
          routerLinkActive="active"
          (click)="click(item)"
        >
          <mat-icon matListIcon>{{ item.icon }}</mat-icon>

          <p matLine [title]="item.name">
            {{ item.name | translate }}
          </p>
        </a>
      </ng-container>
    </mat-nav-list>

    <div class="footer">
      <!-- <div *ngIf="!expanded" class="info" (click)="about()"
              matTooltip="{{'ABOUT.TITLE'|translate}}" matTooltipPosition="right">
              <mat-icon class="mat-secondary-color">info</mat-icon>
            </div>
            <div *ngIf="expanded" class="links">
      
              <div class="msu" ></div>
      
              <div>
                {{copy}}
              </div>
              <div class="mat-secondary-color">
                <span class="link" (click)="about()">
                  {{'ABOUT.TITLE'|translate}}
                </span>
              </div>
            </div> -->
    </div>
  </mat-sidenav>

  <mat-sidenav-content
    [@sidenavContentAnimation]="expanded ? 'expanded' : 'collapsed'"
    (@sidenavContentAnimation.done)="contentAnimationDone()"
  >
    <div style="flex: 1; padding: 0 24px 24px">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
